import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "form", "isDraft", "template" ]

    template() {
        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        const id = this.templateTarget.options[this.templateTarget.selectedIndex].value
        const invoiceIdElement = document.getElementById('invoiceId')

        let url = '/admin/emails/templates/' + id

        if (invoiceIdElement !== null) {
            url = url + '?invoice_id=' + invoiceIdElement.dataset['id']
        }

        fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token': authenticityToken
            }
        })
            .then(response => response.json())
            .then(function(data) {
                document.querySelector('trix-editor').editor.loadHTML(
                    data['content'] + document.querySelector('#signature').value
                )
                document.querySelector('input[name="email[subject]"]').value = data['subject']
            })
    }

    saveDraft(e) {
        e.preventDefault()

        this.isDraftTarget.value = 'true'
        this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true }))
    }

    send(e) {
        e.preventDefault()

        //('send')

        this.isDraftTarget.value = 'false'
        this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true }))
    }

    setSenderAsMe(e) {
        var senderField = document.getElementById('emailFrom')

        if (e.target.value == '1') {
            senderField.disabled = true;
            senderField.readOnly = true;
        } else {
            senderField.disabled = false;
            senderField.readOnly = false;
        }
    }

    addRafieBcc(e) {
        //(e.target);
        var bccInput = document.getElementById('emailBcc')

        if (e.target.checked) {
            bccInput.value = bccInput.value + ", R. Ramos <raffie@virtualcoworker.com>"
        } else {
            bccInput.value = bccInput.value.replace(", R. Ramos <raffie@virtualcoworker.com>", "")
        }
    }

    addJohnBcc(e) {
        //(e.target);
        var bccInput = document.getElementById('emailBcc')

        if (e.target.checked) {
            bccInput.value = bccInput.value + ", John Muscarello <john@virtualcoworker.com>"
        } else {
            bccInput.value = bccInput.value.replace(", John Muscarello <john@virtualcoworker.com>", "")
        }
    }
}
