var lastEvent = null

function hideSpinner() {
    document.getElementById('spinner').style.display = 'none';
    if (document.querySelector('#spinnerText') !== null ) {
        document.querySelector('#spinnerText').classList.add('d-none')
    }
}

function showSpinner() {
    document.getElementById('spinner').style.display = 'block';

    // Pre emptive measure incase the event got stuck, Remove the spinner after 4 seconds
    // setTimeout(() => hideSpinner(), 4000);
}

document.addEventListener('turbo:load', function() {
    //('load')
    hideSpinner()
    lastEvent = 'load'
})

document.addEventListener('turbo:click', function() {
    //('click')
    showSpinner()
    lastEvent = 'click'
})

document.addEventListener('turbo:request-start', function() {
    //('request-start')
    showSpinner()
    lastEvent = 'request-start'
})

document.addEventListener('turbo:visit', function() {
    //('visit')
    showSpinner()
    lastEvent = 'visit'
})

document.addEventListener('turbo:request-end', function() {
    //('request-end')
    hideSpinner()
    lastEvent = 'request-end'
})

document.addEventListener('turbo:render', function() {
    //('render')
    hideSpinner()
    lastEvent = 'render'
})

document.addEventListener('submit', function() {
    //('load')
    showSpinner()
    lastEvent = 'submit'
})

document.addEventListener('turbo:submit-start', function() {
    //('submit-start')
    showSpinner()
    lastEvent = 'submit-start'
})

document.addEventListener('turbo:frame-load', function() {
    //('frame-load')
    hideSpinner()
    lastEvent = 'frame-load'
})

document.addEventListener('turbo:before-render', function() {
    //('before-render')
    hideSpinner()
    lastEvent = 'before-render'
})

document.addEventListener('turbo:before-fetch-response', function() {
    //('before-fetch-response')

    //('LAST EVENT ----' + lastEvent)
    if (lastEvent == 'submit-start') {
      hideSpinner()
    } else {
      setTimeout(function() {
        let spinner = document.querySelector('#spinner')

        if (spinner.style.display !== 'none') {
            document.querySelector('#spinnerText').classList.remove('d-none')
        }
      }, 3000)
    }

    lastEvent = 'before-fetch-response'
})

document.addEventListener('turbo:before-fetch-request', function() {
    //('before-fetch-request')
    showSpinner()
    if (lastEvent == 'click') {
        setTimeout(function() {
          let spinner = document.querySelector('#spinner')

          if (spinner.style.display !== 'none') {
              document.querySelector('#spinnerText').classList.remove('d-none')
          }
        }, 3000)
      }
    lastEvent = 'before-fetch-request'
})

document.addEventListener('ajax:send', function() {
    //('ajax:send')
    showSpinner()
})

document.addEventListener('ajax:complete', function() {
    //('ajax:complete')
    hideSpinner();

    setTimeout(() => hideSpinner(), 1500);
})

