import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
    connect() {
        //("TESTTT")
        this.checkboxAllTarget.addEventListener('change', this.toggleMenu)


        var self = this
        const updateButtons = document.querySelectorAll('.process-payment')
        updateButtons.forEach(function(updateButton) {
            updateButton.addEventListener('click', self.processPayment)
        })

        document.querySelectorAll('.approve-invoices').forEach(function(approveButton) {
            approveButton.addEventListener('click', self.approveInvoices)
        })

        document.querySelectorAll('.export-batch-pdfs').forEach(function(exportButton) {
            exportButton.addEventListener('click', self.exportToPdfs)
        })

        document.querySelectorAll('.send-bulk-email').forEach(function(sendEmailButton) {
            sendEmailButton.addEventListener('click', self.updateEmailBtnAction)
        })

        document.querySelectorAll('.send-bulk-declined-email').forEach(function(sendDeclinedEmailButton) {
            sendDeclinedEmailButton.addEventListener('click', self.updateDeclinedEmailBtnAction)
        })

        super.connect()
    }

    refresh() {
        super.refresh()
        this.toggleMenu()
    }

    toggle(e) {
        super.toggle(e)
        this.toggleMenu()
    }

    toggleMenu() {
        document.querySelector('#invoiceIds').dataset.ids = this.invoiceIds()

        const menu = document.querySelector('#invoiceSelectionMenu')
        if (this.checked.length > 0) {
            menu.classList.remove('d-none')
        } else {
            menu.classList.add('d-none')
        }
    }

    invoiceIds() {
        var invoiceIds = []
        this.checked.forEach(function(checkbox) {
            invoiceIds.push(parseInt(checkbox.value))
        })

        return invoiceIds
    }

    updateEmailBtnAction(e) {
        var btn = document.getElementById('sendBulkEmail')
        var formBtn = btn.parentElement
        var url = location.origin + '/superadmin/invoice_xero/send_xero_email_modal'

        var invoiceIds = document.querySelector('#invoiceIds').dataset.ids

        formBtn.action = url + '?invoice_ids=' + invoiceIds
    }

    updateDeclinedEmailBtnAction(e) {
        var btn = document.getElementById('sendDeclinedBulkEmail')
        var formBtn = btn.parentElement
        var url = location.origin + '/superadmin/invoice_xero/send_xero_email_modal'

        var invoiceIds = document.querySelector('#invoiceIds').dataset.ids

        formBtn.action = url + '?invoice_ids=' + invoiceIds + "&template_type=declined_payment"
    }

    approveInvoices(e) {
        e.preventDefault()

        var check = confirm("Approve Invoices to Xero ?");

        if (check == true) {
            document.querySelector('#spinner').style.display = '';

            var invoiceIds = document.querySelector('#invoiceIds').dataset.ids

            const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
            const url = '/superadmin/invoice_xero/approve_invoices_to_xero'

            var delay = invoiceIds.split(',').length * 1000

            fetch(url, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Accept': 'text/javascript',
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': authenticityToken
                },
                body: JSON.stringify({ invoice_ids: invoiceIds, gateway: e.currentTarget.dataset.gateway })
            }).then(response => {
                //(invoiceIds)
                if (invoiceIds.split(',').length <= 3  ) {
                    setTimeout(function() {
                        document.querySelector('#spinner').style.display = 'none'
                        //("APPPPPPPPP")
                        //(response)
                        window.location.reload();
                      }, delay) } else {
                    document.getElementById('btnBatchApprove').click();
                }
            })
        }
        else {
            return;
        }
    }

    exportToPdfs(e) {
        //('EXP{ORTTTRSDFSDF')
        var check = confirm("Export Selected Invoices as PDFs ?");
        if (check == true) {
            var btn = document.getElementById('exportInvoiceAsPdfs')
            var sorting = document.getElementById('invoiceSort').dataset.sortKey

            var invoiceIds = document.querySelector('#invoiceIds').dataset.ids

            btn.href = btn.href + '?invoice_ids=' + invoiceIds + "&sortKey=" + sorting
        } else {
            e.preventDefault()
        }
    }

    processPayment(e) {
        e.preventDefault()

        var check = confirm("Process Payment on Selected Invoices ?");

        if (check == true) {
            document.querySelector('#spinner').style.display = '';

            var invoiceIds = document.querySelector('#invoiceIds').dataset.ids
            if (typeof invoiceIds == 'string') {
                invoiceIds = [ invoiceIds ]
            }

            const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
            const action = e.currentTarget.dataset.gateway + '_process_batch_payment'
            const url = '/superadmin/invoice_payments/' + action + '?invoice_ids=' + invoiceIds.join(',')

            fetch(url, {
                credentials: 'include',
                method: 'GET',
                headers: {
                  'X-CSRF-Token': authenticityToken
                }
            }).then (
                setTimeout(function() {
                    document.querySelector('#spinner').style.display = 'none'
                    let path = ""

                    if (!invoiceIds[0].includes(',')) {
                        path = "?q%5Bgroup%5D=single_payments"
                    }

                    //(invoiceIds)

                    Turbo.visit('/superadmin/invoice_payments/batch_payment_logs' + path)
                  }, 2000)
            )
        }
        else {
            return;
        }
    }
}
