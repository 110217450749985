import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
    connect() {
        //('test')
        const input = document.querySelector("#phone");

        var countryData = document.getElementById('countryData')

        intlTelInput(input, {
            separateDialCode: true,
            hiddenInput: 'full_number',
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.js",
            preferredCountries: ["us", "au", "nz", "ph"],
            onlyCountries: countryData.dataset.countries.split(', '),
            initialCountry: countryData.dataset.preferredCountry
        });
    }
}
