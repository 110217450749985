import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    reset() {
        document.querySelector('#classifications-list').classList.add('d-none')
        document.querySelector('#countries-list').classList.add('d-none')
        document.querySelector('#selection-list').classList.add('d-none')

        if (document.querySelector('#zipCodeField')) {
            document.querySelector('#zipCodeField').classList.add('d-none')
        }
    }

    addRecipients(e) {
        const selected = document.querySelectorAll('select[name="recipient_ids[]"] option')
        const to = Array.from(selected).map(el => el.textContent)

        document.getElementById("broadcastRecipientHelperText").innerHTML = `Total of ${to.length} Recipients. Each Recipient will receive a separate email`
        document.querySelector('textarea[name="email[to]"]').value = to.join(', ')
    }

    showClassifications(e) {
        this.reset()
        document.querySelector('#classifications-list').classList.remove('d-none')
    }

    showCountries(e) {
        this.reset()
        document.querySelector('#countries-list').classList.remove('d-none')
    }

    showSelection(e) {
        this.reset()
        document.querySelector('#selection-list').classList.remove('d-none')
    }

    showZipCode(e) {
        this.reset()
        document.querySelector('#zipCodeField').classList.remove('d-none')
    }

     // New Stimulus Methods Base from Migrating Jquery from emails.js

     onBtnRightClick(event) {
        //('test')
        //(event);
        event.preventDefault();

        let listBox1 = document.getElementById('lstBox1');
        let listBox2 = document.getElementById('lstBox2');

        let selectedOption = listBox1.options[listBox1.selectedIndex]
        if (selectedOption) {
            let clone = selectedOption.cloneNode(true)
            listBox2.append(clone)
            selectedOption.remove()
            this.calculateListBoxChilds()
        }
    }

    onBtnLeftClick(event) {
        //(event);
        event.preventDefault();

        let listBox1 = document.getElementById('lstBox1');
        let listBox2 = document.getElementById('lstBox2');

        let selectedOption = listBox2.options[listBox2.selectedIndex]
        if (selectedOption) {
            let clone = selectedOption.cloneNode(true)
            listBox1.append(clone)
            selectedOption.remove()
            this.calculateListBoxChilds()
        }
    }

    calculateListBoxChilds() {
        let listBox1Elements = document.querySelector('#lstBox1').length;
        let listBox2Elements = document.querySelector('#lstBox2').length;
        let btnRight = document.getElementById('btnRight')
        let btnLeft = document.getElementById('btnLeft')

        document.querySelector('#list1-badge').innerHtml = listBox1Elements
        document.querySelector('#list2-badge').innerHTML = listBox2Elements

        if (listBox1Elements == 0) { btnRight.classList.add('d-none'); }
        else { btnRight.classList.remove('d-none'); }

        if (listBox2Elements == 0) { btnLeft.classList.add('d-none'); }
        else { btnLeft.classList.remove('d-none'); }
    }

    removeModalBacdrop() {
        document.body.classList.remove('modal-open')
        let modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
        if (modalBackdrop) { modalBackdrop.remove(); }
    }
}
