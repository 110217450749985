import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "showButton", "target", "template" ]
    static values = { wrapperSelector: String, actionSelector: String }

    connect() {
        //("asdfasdfasfdasdsssf")
        if (!document.location.href.includes('edit')) {
            const content = this.templateTarget.innerHTML
            this.targetTarget.innerHTML = content
            this.showButtonTarget.classList.add('d-none')
        }
    }

    show(e) {
        e.preventDefault()

        const content = this.templateTarget.innerHTML
        this.targetTarget.innerHTML = content
        this.showButtonTarget.classList.add('d-none')
    }

    remove(e) {
        e.preventDefault()

        this.targetTarget.innerHTML = ''
        this.showButtonTarget.classList.remove('d-none')
    }
}
