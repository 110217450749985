import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    updateFormOnCategory(e) {
        //(e.target);

        if (e.target.value == 'in_house') {
            document.getElementById('inHouse').classList.remove('d-none')
            document.getElementById('outsider').classList.add('d-none')
        } else {
            document.getElementById('inHouse').classList.add('d-none')
            document.getElementById('outsider').classList.remove('d-none')
        }
    }

    updateIncentiveField(e) {
        if (e.target.value == 'non_tech') {
            document.getElementById('incentiveAmount').disabled = true;
        } else {
            document.getElementById('incentiveAmount').disabled = false;
        }
    }
}
