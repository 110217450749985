import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "amount",
    "dollar",
    "isRecurringCheckbox",
    "oneOffFeeCheckbox",
    "oneOffFieldsTemplate",
    "oneOffFields",
    "useFixedPesoAmountCheckbox",
    "allowanceTypeDropdown"
  ]

  connect() {
    if (document.getElementById('modal')?.src?.includes('allowances')) {
      this.toggleAmount()
      this.toggleIsRecurring()
      this.toggleOneOffFee()
      this.toggleAllowanceType()
    }
  }

  switchExchangeRate(e) {
    var currency = e.currentTarget.value
    var exchangeRateField = document.querySelector('input[name="allowance[exchange_rate]"]')

    if (currency == 'aud') {
      var exchangeRate = parseFloat(document.getElementById('audExchange').dataset.rate)
      exchangeRateField.value = exchangeRate
    } else if (currency = 'usd') {
      var exchangeRate = parseFloat(document.getElementById('usdExchange').dataset.rate)
      exchangeRateField.value = exchangeRate
    }

    var amountInDollar = document.querySelector('input[name="allowance[amount_in_dollar]"]')
    var pesoAmount = document.querySelector('input[name="allowance[amount]"]')

    if (!amountInDollar.value == false && !this.useFixedPesoAmountCheckboxTarget.checked) {
      const exchangeRate = parseFloat(document.querySelector('input[name="allowance[exchange_rate]"]').value)
      const peso = (parseFloat(amountInDollar.value) * exchangeRate).toFixed(2)

      this.amountTarget.value = peso
    }

    amountInDollar.parentElement.parentElement.querySelector('.form-label').innerHTML = "Amount in $ (Exchange Rate is " + exchangeRate.toFixed(2) + ")"
  }

  dollarToPeso(e) {
    if (isNaN(e.currentTarget.value) == false && !this.useFixedPesoAmountCheckboxTarget.checked) {
      const exchangeRate = parseFloat(document.querySelector('input[name="allowance[exchange_rate]"]').value)
      const peso = (parseFloat(e.currentTarget.value) * exchangeRate).toFixed(2)

      this.amountTarget.value = peso
    }
  }

  pesoToDollar(e) {
    if (isNaN(e.currentTarget.value) == false && this.oneOffFeeCheckboxTarget.checked) {
      const exchangeRate = parseFloat(document.querySelector('input[name="allowance[exchange_rate]"]').value)
      const dollar = (parseFloat(e.currentTarget.value) / exchangeRate).toFixed(2)

      this.dollarTarget.value = dollar
    }
  }

  toggleAmount() {
    //('toggleAmount()')
    if (this.useFixedPesoAmountCheckboxTarget.checked) {
      //('checked')
      this.amountTarget.name = 'allowance[fixed_peso_amount]'
      this.amountTarget.readOnly = false
      this.amountTarget.disabled = false
    } else {
      //('unchecked')
      this.amountTarget.name = 'allowance[amount]'
    }
  }

  toggleUseFixedPesoWrapper() {
    const wrapper = document.querySelector('#used-fixed-peso-checkbox-wrapper')
    if (this.isRecurringCheckboxTarget.checked || this.oneOffFeeCheckboxTarget.checked) {
      wrapper.classList.remove('d-none')
      this.amountTarget.name = 'allowance[amount]'
    } else {
      wrapper.classList.add('d-none')
    }
  }

  toggleOneOffFee() {
    if (this.oneOffFeeCheckboxTarget.checked) {
      const content = this.oneOffFieldsTemplateTarget.innerHTML
      this.oneOffFieldsTarget.innerHTML = content

      const amount = document.querySelector('input[name="allowance[amount]"]')
      /*
      if (amount !== null) {
          amount.readOnly = true
          amount.disabled = true
      }
      */

      document.querySelectorAll('input[name="allowance[is_recurring]"]')[1].checked = false
    } else {
      document.querySelector('#one-off-fields-wrapper').innerHTML = ''

      const amount = document.querySelector('input[name="allowance[amount]"]')
      if (amount !== null) {
        amount.readOnly = false
        amount.disabled = false
      }
    }
    this.toggleUseFixedPesoWrapper()
  }


  toggleAllowanceType() {
    if (this.allowanceTypeDropdownTarget.value  === 'non_invoiceable_bonus') {
      document.querySelector('#allowance-currency').classList.add('d-none');
      document.querySelector('#generate-invoice-wrapper')?.classList.add('d-none');
      document.querySelector('#used-fixed-peso-checkbox-wrapper').classList.add('d-none');
    } else {
      document.querySelector('#allowance-currency').classList.remove('d-none');
      document.querySelector('#generate-invoice-wrapper')?.classList.remove('d-none');
      document.querySelector('#used-fixed-peso-checkbox-wrapper').classList.remove('d-none');

    }
  }

  toggleIsRecurring() {
    if (this.isRecurringCheckboxTarget.checked) {
      const content = this.oneOffFieldsTemplateTarget.innerHTML
      this.oneOffFieldsTarget.innerHTML = content

      document.querySelector('input[name="allowance[amount]"]').readOnly = true
      document.querySelector('#generate-invoice-wrapper').classList.add('d-none')
      document.querySelector('input[name="allowance[amount_in_dollar]"]').value = ''
      document.querySelectorAll('input[name="allowance[is_one_off_fee]"]')[1].checked = false
    } else {
      document.querySelector('#one-off-fields-wrapper').innerHTML = ''

      const amount = document.querySelector('input[name="allowance[amount]"]')
      if (amount !== null) {
        amount.readOnly = false
        amount.disabled = false
      }
    }
    this.toggleUseFixedPesoWrapper()
  }

  remove(e) {
    e.preventDefault()

    this.targetTarget.innerHTML = ''
    this.showButtonTarget.classList.remove('d-none')
  }


  computeDeductions() {
    var loanAmount = document.getElementById('loanAmount').value
    var monthsToPay = document.getElementById('monthsToPay').value
    var payrollStatus = document.getElementById('info').dataset.payrollName
    var releaseStart = new Date(document.getElementById('info').dataset.releaseStart)
    var releaseEnd = new Date(document.getElementById('info').dataset.releaseEnd)

    if (loanAmount && monthsToPay !== '') {
      if (payrollStatus == "Semi-Monthly") {
        var deductionStart = new Date(releaseStart.setDate(releaseStart.getDate() + 15))
        var deductionEnd = new Date(releaseStart.setMonth(deductionStart.getMonth() + Number(monthsToPay)))
        var deduction = Number(loanAmount) / Number(monthsToPay)
        var actualDeduction = deduction / 2
      } else {
        var deductionStart = new Date(releaseStart.setMonth(releaseStart.getMonth() + 1))
        var deductionEnd = new Date(releaseStart.setMonth(deductionStart.getMonth() + Number(monthsToPay)))
        var deduction = Number(loanAmount) / Number(monthsToPay)
        var actualDeduction = deduction
      }

      var loanComputation = document.getElementById('loanComputation')
      loanComputation.classList.remove('d-none')

      document.getElementById('loanAmountDisplay').innerHTML = "Total Loan Amount: ₱" + this.numberWithCommas(this.roundedNumber(loanAmount))
      document.getElementById('loanDeduction').innerHTML = "Monthly Deduction: ₱" + this.numberWithCommas(this.roundedNumber(deduction))
      document.getElementById('loanDeductionPerPayroll').innerHTML = "Per Payroll Period Deduction: ₱" + this.numberWithCommas(this.roundedNumber(actualDeduction))
      document.getElementById('deductionStart').innerText = "Deduction Payroll Start: " + deductionStart.toDateString()
      document.getElementById('deductionEnd').innerText = "Deduction Payroll End: " + deductionEnd.toDateString()
    }

  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  roundedNumber(amt) {
    return Number((Math.round(Number(amt) * 100) / 100).toFixed(2))
  }
}
