import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "submitButton" ]

    connect() {
        var dataCount = document.getElementById('payslipData')
        var btn = document.getElementById('submitBtn')

        var payslipCount = Number(dataCount.dataset.payslipCount)
        var exportedAlreadyCount = Number(dataCount.dataset.exportedAlreadyCount)

        if (payslipCount == exportedAlreadyCount) {
            btn.value = "All Selected Payslips has been Exported already"
            btn.disabled = true;
        }
    }

    toggleExportOption() {
        var dataCount = document.getElementById('payslipData')
        var btn = document.getElementById('submitBtn')

        var payslipCount = Number(dataCount.dataset.payslipCount)
        var exportedAlreadyCount = Number(dataCount.dataset.exportedAlreadyCount)

        var toggle = document.getElementById('include_only_without_report')

        if (!toggle.checked) {
            btn.value = "Download Report"
            btn.disabled = false;
        }
        else if (toggle.checked && payslipCount == exportedAlreadyCount) {
            btn.value = "All Selected Payslips has been Exported already"
            btn.disabled = true;
        }
    }

    reloadPage(event) {
        //(this);
        //('test');

        var btn = document.getElementById('submitBtn')
        btn.disabled = true;
        btn.value = "Exporting CSV ..."

        document.getElementById('wiseForm').submit();
        setTimeout(function() {
            window.location.reload();
          }, 7500)
    }


}
